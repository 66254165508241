<template>
  <v-container>
    <v-row class="pt-3">
      <v-app-bar
        flat
        dense
        color="transparent"
      >
        <h1 class="text-h5 primary--text">Banners</h1>
      </v-app-bar>
      <v-col
        cols="12"
        lg="12"
      >
        <SectionTitle title="Carrossel" />
        <v-row v-if="top_banners != null">
          <v-col
            v-for="(item, idx) in top_banners"
            :key="idx"
            cols="12"
            lg="12"
          >
            <BannerCard
              :type="type_top_banner"
              :top_banner="item"
              img=""
            />
          </v-col>
        </v-row>
        <div v-else>
          <BannerCard
            img="https://images.unsplash.com/photo-1661961110671-77b71b929d52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          />
        </div>
        <v-btn
          class="primary"
          rounded
          @click="uploadFile1"
        >
          Adicionar imagem
          <v-icon
            color="white"
            small
            right
            >fas fa-plus</v-icon
          >
        </v-btn>
        <input
          type="file"
          accept="image/*"
          class="d-none"
          ref="uploader1"
          @change="onFileChange"
        />
        <v-row align="center">
          <v-col
            cols="12"
            lg="12"
          >
            <SectionTitle title="Banner da seção" />
            <BannerCard
              :type="type_section"
              img="https://images.unsplash.com/photo-1661961110671-77b71b929d52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            lg="12"
          >
            <SectionTitle title="Banner do rodapé" />
            <BannerCard
              :type="type_client_banner"
              img="https://images.unsplash.com/photo-1666346681960-0229afbc3d10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            lg="12"
          >
            <SectionTitle title="Banner de produtos" />
            <BannerCard
              :type="type_product"
              img="https://images.unsplash.com/photo-1666297908905-6d047acc3172?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
            />
          </v-col>
        </v-row>

        <SectionTitle title="Clientes" />
        <v-row align="center">
          <v-col
            v-for="(item, idx) in clients"
            :key="idx"
            cols="12"
            lg="2"
          >
            <BannerCard
              :type="type_clients"
              :client_image="item"
              img="https://images.unsplash.com/photo-1666297908905-6d047acc3172?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
            />
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
            <v-sheet
              class="overflow-hidden my-2 primary align-center justify-center d-flex py-8"
              style="cursor: pointer"
              rounded="xl"
              max-height="200"
              @click="uploadFile"
            >
              <v-icon
                color="white"
                large
                >fas fa-plus</v-icon
              >
            </v-sheet>
            <input
              type="file"
              accept="image/*"
              class="d-none"
              ref="uploader"
              @change="onFileChange2"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  mixins: [checkProfileRoutePermission],
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    BannerCard: () => import('./BannerCard'),
  },
  data() {
    return {
      top_banners: null,
      clients: null,
      site: {},
      type_top_banner: { name: 'top_banner' },
      type_section: { name: 'card' },
      type_client_banner: { name: 'client_banner' },
      type_clients: { name: 'clients' },
      type_product: { name: 'product' },
    }
  },
  methods: {
    postBannerTop() {
      const formData = new FormData()
      const siteKeys = Object.keys(this.site)
      const siteValues = Object.values(this.site)
      for (let i = 0; i < siteKeys.length; i++) {
        if (siteValues[i] !== null) {
          formData.append(siteKeys[i], siteValues[i])
        }
      }
      this.$api
        .post('site/homes', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          if (this.site.type == 'top_banner') {
            this.getTopBanner()
          } else if (this.site.type == 'clients') {
            this.getClients()
          }
          // location.reload();
        })
        .catch((err) => {
          console.log(err)
        })
    },
    uploadFile() {
      this.$refs.uploader.click()
    },
    uploadFile1() {
      this.$refs.uploader1.click()
    },
    onFileChange(e) {
      this.imagePreview = URL.createObjectURL(e.target.files[0])
      this.site.image_path = e.target.files[0]
      this.site.type = 'top_banner'
      this.postBannerTop()
    },
    onFileChange2(e) {
      this.imagePreview = URL.createObjectURL(e.target.files[0])
      this.site.image_path = e.target.files[0]
      this.site.type = 'clients'
      this.postBannerTop()
    },
    getTopBanner() {
      this.$api
        .get('site/homes/top_banner', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.top_banners = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getClients() {
      this.$api
        .get('site/homes/clients', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.clients = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getTopBanner()
    this.getClients()
  },
}
</script>
